import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { Helmet } from 'react-helmet-async';
import Bill from './components/Bill';
import InviteResult from './components/InviteResult';

// Add this function at the top of your file, after the imports
const trackStoreClick = (storeName) => {
  if (window.gtag) {
    window.gtag('event', 'click', {
      'event_category': 'app_store',
      'event_label': storeName,
    });
  }
};

// Separate the main landing page content into its own component
function Home() {
  return (
    <main className="landing-container">
      <div className="hero-section">
        <div className="hero-content">
          <h1>InstaLedgerX</h1>
          <p className="hero-description">
            Manage your business effortlessly with InstaLedgerX. Designed specifically for businesses, 
            our app simplifies sales, inventory tracking, and customer account management.
          </p>
          <div className="app-store-buttons">
            <a 
              href="https://play.google.com/store/apps/details?id=com.sfims" 
              className="store-button"
              onClick={() => trackStoreClick('Google Play')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/playstore.png" alt="Get it on Google Play" />
            </a>
            <a 
              href="https://apps.apple.com/in/app/instaledgerx/id6615070209" 
              className="store-button"
              onClick={() => trackStoreClick('App Store')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/appstore.svg" alt="Download on App Store" />
            </a>
          </div>
        </div>
        <div className="hero-image">
          <img src="/hero_image_instaledgerx_1200x800.png" alt="InstaLedgerX App Interface" />
        </div>
      </div>

      <section className="features-section">
        <h2>Key Features</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Quick Sales & Invoicing</h3>
            <p>Quick sale logging and detailed invoice creation</p>
          </div>
          <div className="feature-card">
            <h3>Inventory Management</h3>
            <p>Real-time inventory management and tracking</p>
          </div>
          <div className="feature-card">
            <h3>Customer Accounts</h3>
            <p>Customer account management with credit and debit tracking</p>
          </div>
          <div className="feature-card">
            <h3>Business Analytics</h3>
            <p>Comprehensive business reporting and analytics</p>
          </div>
        </div>
      </section>
    </main>
  );
}

// Support page component
function Support() {
  return (
    <div className="page-container">
      <h1>Support</h1>
      <div className="support-content">
        <section className="support-section">
          <h2>Contact Us</h2>
          <p>For any questions or support regarding InstaLedgerX, please contact us:</p>
          <ul>
            <li>Email: support@instaledgerx.com</li>
            <li>Response Time: Within 24-48 hours</li>
          </ul>
        </section>
        
        <section className="support-section">
          <h2>FAQ</h2>
          <div className="faq-item">
            <h3>How do I get started?</h3>
            <p>Download the app from App Store or Google Play Store and follow the simple registration process.</p>
          </div>
          {/* Add more FAQ items as needed */}
        </section>
      </div>
    </div>
  );
}

// Terms & Privacy Policy page component
function Terms() {
  return (
    <div className="page-container">
      <h1>Terms & Privacy Policy</h1>
      <div className="terms-content">
        <section className="terms-section">
          <h2>Privacy Policy</h2>
          <p>Last updated: [Date]</p>
          <div className="policy-content">
            <h3>Information We Collect</h3>
            <p>We collect information that you provide directly to us when using InstaLedgerX:</p>
            <ul>
              <li>Business information</li>
              <li>Contact details</li>
              <li>Transaction data</li>
              <li>Usage information</li>
            </ul>

            <h3>How We Use Your Information</h3>
            <p>We use the collected information to:</p>
            <ul>
              <li>Provide and maintain our services</li>
              <li>Improve user experience</li>
              <li>Send important updates</li>
              <li>Ensure security of your data</li>
            </ul>
            
            {/* Add more privacy policy sections as needed */}
          </div>
        </section>

        <section className="terms-section">
          <h2>Terms of Service</h2>
          <p>By using InstaLedgerX, you agree to these terms of service...</p>
          {/* Add terms of service content */}
        </section>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>InstaLedgerX - Simplify Your Business Management</title>
          <meta name="description" content="Manage your business effortlessly with InstaLedgerX. Designed specifically for businesses, our app simplifies sales, inventory tracking, and customer account management." />
          <meta property="og:title" content="InstaLedgerX - Simplify Your Business Management" />
          <meta property="og:description" content="Manage your business effortlessly with InstaLedgerX. Our app simplifies sales, inventory tracking, and customer account management." />
          <meta property="og:image" content="https://instaledgerx.com/hero_image_instaledgerx_1200x800.png" />
          <meta property="og:url" content="https://instaledgerx.com" />
          <meta property="og:type" content="website" />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/bill/:billHashId" element={<Bill />} />
          <Route path="/accept-invite" element={<InviteResult />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
