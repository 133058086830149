import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

function InviteResult() {
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState({ loading: true });
  const processedRef = useRef(false);

  useEffect(() => {
    const token = searchParams.get('token');
    const orgId = searchParams.get('orgId');
    const email = searchParams.get('email');
    const env = searchParams.get('env');

    // Determine base URL based on env parameter
    let baseUrl;
    if (env === 'dev') {
      baseUrl = 'http://localhost:8080';
    } else if (env === 'demo') {
      baseUrl = 'https://demo-ims-api.instaledgerx.com';
    } else {
      baseUrl = 'https://prod-ims-api.instaledgerx.com';
    }

    if (token && orgId && email && !processedRef.current) {
      processedRef.current = true;
      
      fetch(`${baseUrl}/sfims/invitation/accept-invite?token=${token}&orgId=${orgId}&email=${email}`)
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            setResult({
              loading: false,
              success: true,
              message: "Invitation accepted successfully!",
              email: email
            });
          } else {
            setResult({
              loading: false,
              success: false,
              message: data.data || "Failed to process invitation",
              email: email
            });
          }
        })
        .catch(error => {
          setResult({
            loading: false,
            success: false,
            message: "Failed to process invitation"
          });
        });
    } else if (!token || !orgId || !email) {
      setResult({
        loading: false,
        success: false,
        message: "Invalid invitation link"
      });
    }
  }, [searchParams]);

  const trackStoreClick = (storeName) => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'invite_result_store',
        'event_label': storeName,
      });
    }
  };

  if (result.loading) {
    return <div className="container">Loading...</div>;
  }

  return (
    <div className="container">
      <h1>InstaLedgerX</h1>
      
      <h2 className={result.success ? 'success' : 'error'}>
        {result.message}
      </h2>
      
      {result.success && (
        <>
          <p className="message">
            Your invitation has been accepted. Please download our mobile app and login with your email{' '}
            <span style={{ fontWeight: 'bold' }}>{result.email}</span>
          </p>
          
          <div className="app-stores">
            <a 
              href="https://play.google.com/store/apps/details?id=com.sfims" 
              className="store-button"
              onClick={() => trackStoreClick('Google Play')}
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src="/playstore.png" alt="Get it on Google Play" />
            </a>
            <a 
              href="https://apps.apple.com/in/app/instaledgerx/id6615070209" 
              className="store-button"
              onClick={() => trackStoreClick('App Store')}
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src="/appstore.svg" alt="Download on App Store" />
            </a>
          </div>
        </>
      )}
      
      {!result.success && (
        <p className="message">If you continue to have issues, please contact support.</p>
      )}
    </div>
  );
}

export default InviteResult; 